<script>
import moment from "moment";
export default {
  created() {
    this.time = moment().format("yyyy-MM-DD HH:mm:ss");
    this.timer = setInterval(() => {
      this.time = moment().format("yyyy-MM-DD HH:mm:ss");
    }, 1000);
  },
  data() {
    return {
      time: "", // 当前时间
      timer: null, // 定时器
      value: "现代筑美",
      value1: "安徽省 合肥市 蜀山区",
      value2: "现代筑美 东部-厂区",
      seal: {}, //实时监控

      showDown: false,
      img: true,
      checkArr: [],
      activeIndex: 1,
      id: "1",
      rtsp: "rtsp://admin:Cc123123@114.55.113.180:554/dac/realplay/CFD08F71-6165-7947-A432-6F78148193A31/MAIN/TCP?streamform=rtp",
      player: null,
      playerOptions: {
        //视频url设置,直播流为例
        sources: [{
          src: 'https://yunweibang.nengliangkeji.com/live/34020000001320000001_34020000001320000001/hls.m3u8',//视频文件地址
          // type: 'application/x-mpegURL'//视频类型，这里可以不写，如果写一定要写对，否则会无法播放
        }],
        // 其他设置项
        notSupportedMessage: "此视频暂无法播放，请稍后再试",//提示信息
        autoplay: true,//是否自动播放
        controls: true,//是否显示控制栏
        poster: 'http://path/to/poster.jpg',//视频封面
      }
    };
  },
  created() {
    this.getSeal();
  },
  computed: {
    total() {
      return this.checkArr.length;
    },
  },
  mounted() {
    this.getList();
    this.isInit = true;
  },
  methods: {
    fullScreen() {
      this.$refs.video.requestFullscreen();
    },
    getList() {
      this.$http.post("powerStationMonitor/list", {
        powerStationCode: localStorage.getItem("powerStationCode")
      }).then((res) => {
        if (res.data.code === 200) {
          this.checkArr = res.data.data.data;
          this.init();
        } else {
        }
      });
    },
    // 获取首页实时监控
    async getSeal() {
      const {
        data: { data },
      } = await this.$http.post("/RealMonitoring/PCSeal", {
        powerStationCode: localStorage.getItem("powerStationCode")
      });

      this.seal = data;
      let index = this.seal.timeDay.indexOf("天");
      this.seal.timeDay = this.seal.timeDay.substring(0, index + 1);
    },
    init() {
      this.$nextTick(() => {
        //获取dom节点
        for (var i = 0; i < this.checkArr.length; ++i) {
          let str = "vlc" + i;
          var vlc = document.getElementById(str);
          console.log(vlc, str, i);
          var options = new Array("rtsp-tcp");
          var id = vlc.playlist.add(
            this.checkArr[i].palyUrl,
            "fancy name",
            options
          );
          vlc.playlist.playItem(id);
        }
      });
    },
    downloads() {
      let a = document.createElement("a");
      a.download = "vlc.exe";
      a.href = "../assets/vlc.exe";
      a.click();
      //  this.$notify({
      //   title: '成功',
      //   message: '这是一条成功的提示消息',
      //   type: 'success'
      // });
    },
    error(e) {
      this.showDown = true;
      this.$notify({
        title: "提示",
        message: "请点击按钮安装插件后，使用360浏览器极速模式查看视频",
        type: "info",
      });
    },
  },
  beforeDestory() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>
<template>
  <div class="center-main">
    <div class="head-main">
      <div class="left">实时监控</div>
      <!-- <div class="right">
        <el-select
          class="diy-select"
          v-model="value"
          placeholder="请选择"
          size="small"
        >
          <el-option label="item.label" value="item.value" />
        </el-select>
      </div> -->
    </div>
    <div class="head-bottom">
      <div class="video-area">
        <!-- <div @click="fullScreen" class="full-screen">
          <i class="el-icon-full-screen"></i>
        </div> -->
        <div class="time">{{ time }}</div>
        <!-- <div class="title">东部-厂区</div> -->
        <div style="height: 100%; width: 100%">
          <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :options="playerOptions"></video-player>
          <!-- <object
            type="application/x-vlc-plugin"
            id="vlc0"
            events="True"
            width="100%"
            height="100%"
            pluginspage="http://www.videolan.org"
            codebase="http://downloads.videolan.org/pub/videolan/vlc-webplugins/2.2.2/npapi-vlc-2.2.2.tar.xz"
          >
            <param name="volume" value="0" />
            <param name="autoplay" value="true" />
            <param name="loop" value="false" />
            <param name="fullscreen" value="true" />
          </object> -->
        </div>
      </div>
      <div>
        <!-- <div class="text-area">
          <el-select
            class="diy-select"
            v-model="value1"
            placeholder="请选择"
            size="small"
          >
            <el-option label="item.label" value="item.value" />
          </el-select>
          <el-select
            class="diy-select"
            style="margin-top: 0.15rem"
            v-model="value2"
            placeholder="请选择"
            size="small"
          >
            <el-option label="item.label" value="item.value" />
          </el-select>
        </div> -->
        <div class="text-con">
          <div class="flex">
            <div class="title2">状&emsp;&emsp;态</div>
            <div class="title2">
              <span v-if="checkArr[0].status == 0" class="success"></span>
              <span v-if="checkArr[0].status == 1" class="error"></span>
              {{ checkArr[0].status === 0 ? "正常" : "故障" }}
            </div>
          </div>
          <div class="flex">
            <div class="title2">装机容量</div>
            <div class="title2">{{ checkArr[0].installedCapacity }}kW</div>
          </div>
          <div class="flex">
            <div class="title2">投运时间</div>
            <div class="title2">{{ checkArr[0].operationTime }}</div>
          </div>
          <div class="flex">
            <div class="title2">运行天数</div>
            <div class="title2 ellipsis">{{ checkArr[0].runningDays }}</div>
          </div>
          <div class="flex">
            <div class="title2">联系人</div>
            <!-- <div class="title2">{{ checkArr[0].linkUser }}</div> -->
            <div class="title2">中亮新能源</div>
          </div>
          <div class="flex">
            <div class="title2">联系方式</div>
            <div class="title2">{{ checkArr[0].linkNum }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
/deep/.diy-select {
  width: 100%;

  .el-select__placeholder {
    color: #97b3cb;
  }

  i {
    line-height: 1;
  }

  input {
    padding: 2px 8px;
    height: 24px;
    color: #97b3cb;
    line-height: 24px;
    font-size: 12px;
    background: transparent;
    box-shadow: 0 0 0 1px rgba(0, 107, 154, 1) inset;
    border-color: rgba(0, 107, 154, 1);
  }
}

::v-deep .video-js {
  height: 18vw;
}

.center-main {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #132c45;
  border-radius: 1px;
  border: 1px solid #026795;

  .head-main {
    display: flex;
    border-bottom: 1px solid #075279;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    color: #02a7f0;
    font-size: 18px;
    font-weight: 600;

    .right {
      width: 200px;
      height: 25px;
      display: flex;
      align-items: center;
    }
  }

  .head-bottom {
    flex-grow: 1;
    display: flex;
    flex-basis: 0;
    box-sizing: border-box;
    padding: 15px 10px;

    .video-area {
      flex-grow: 1;
      display: flex;
      flex-basis: 0;
      height: 380px;
      box-sizing: border-box;
      overflow: hidden;
      padding-right: 10px;
      position: relative;
      padding-top: 10px;

      .full-screen {
        position: absolute;
        z-index: 2024;
        right: 10px;
        bottom: 10px;

        i {
          color: #fff;
          font-size: 18px;
          cursor: pointer;
        }
      }

      .time {
        position: absolute;
        z-index: 2024;
        right: 10px;
        top: 10px;
        font-weight: 600;
        color: #fff;
        font-size: 20px;
      }

      .title {
        position: absolute;
        z-index: 2024;
        left: 10px;
        bottom: 10px;
        font-weight: 600;
        color: #fff;
        font-size: 18px;
      }

      video {
        height: 300px;
        // aspect-ratio: 17/9;
        width: 100%;
        object-fit: fill;
      }
    }

    .text-area {
      width: 200px;
    }

    .text-con {
      color: #97b3cb;
      margin-top: 15px;
      font-size: 16px;
    }
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 15px;

  .title2 {
    width: 90px;
    height: 20px;
    line-height: 20px;
    text-align: start;

    .success {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #00ff00;
      border-radius: 50%;
    }

    .error {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #ff0000;
      border-radius: 50%;
    }
  }
}

//文本省略
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
