import * as echarts from "echarts";
export const IndexCenterRightOptions = {
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c}台)'
  },
  grid: {
    left: "12%",
    top:'12%',
    bottom:'15%'
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    boundaryGap: true,
    data: ["P(kw)", "q(kwa)", "cos", "F(hz)", "Fri", "Sat"],
    axisTick: {
      show: true, // 是否显示刻度线
      length: 5, // 刻度线长度
      lineStyle: {
        color: "#97b3cb", // 刻度线颜色
      },
    },
    // 刻度线颜色
    axisLine: {
      lineStyle: {
        color: "#fff",
        width: 1,
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: "#97b3cb", // x轴颜色
      },
    },
  },
  yAxis: {
    type: "value",
    axisTick: {
      show: true, // 是否显示刻度线
      length: 3, // 刻度线长度
      lineStyle: {
        color: "#97b3cb", // 刻度线颜色
      },
    },
    splitLine: {
      lineStyle: {
        type: "dashed", // 线型为虚线
        color:"#97b3cb"
      },
    },
    axisLabel: {
      textStyle: {
        color: "#97b3cb",
      },
    },
    // 修改刻度尺颜色
    axisLine: {
      lineStyle: {
        color: "#fff",
        width: 1,
      },
    },
  },
  series: [
    {
      data: [370, 700, 200, 400, 300, 300],
      type: "line",
      lineStyle: {
        color: "#ec808d",
      },
      smooth: true,
      label: {
        show: true, // 开启标签显示
        position: "top", // 标签的位置，可以根据需要调整
        textStyle: {
          color: "#ec808d",
        },
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "rgba(73, 65, 87,1)" }, // 起始颜色及位置
          { offset: 1, color: "rgba(190, 91, 96, 1)" }, // 终止颜色及位置
        ]),
      },
      axisLabel: {
        textStyle: {
          color: "#97b3cb",
        },
      },
    },
  ],
};
