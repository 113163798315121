import * as echarts from "echarts";

// 底部左侧数据
export const indexBottomLeft = {
  tooltip: {
    trigger: "item",
    formatter: "{b} : {c}台)",
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: [
    {
      type: "category",
      data: ["P(kw)", "q(kwa)", "cos", "F(hz)", "Fri", "Sat"],
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#97b3cb", // x轴颜色
        },
      },
      axisLine: {
        lineStyle: {
          color: "#fff",
          width: 1,
        },
      },
    },
  ],
  yAxis: [
    {
      type: "value",
      axisLabel: {
        show: true,
        textStyle: {
          color: "#97b3cb", // x轴颜色
        },
      },
      splitLine: {
        lineStyle: {
          type: "dashed", // 线型为虚线
          color: "#97b3cb",
        },
      },
      axisLine: {
        lineStyle: {
          color: "#fff",
          width: 1,
        },
      },
    },
  ],
  series: [
    {
      name: "Direct",
      type: "bar",
      barWidth: "25%",
      data: [370, 700, 200, 400, 300, 300],
      itemStyle: {
        color: "#45f9d6",
      },
      label: {
        show: true, // 开启标签显示
        position: "top", // 标签的位置，可以根据需要调整
        textStyle: {
          color: "#45f9d6",
        },
      },
    },
  ],
};
// 底部中间数据
export const indexBottomCenter = {
  backgroundColor: "#132c45",
  tooltip: {
    trigger: "axis",
    formatter: function (data) {
      let html = ``;
      html = `
      <div>${data[0].name}</div>
        <div>${data[0].marker} ${data[0].seriesName}: ${data[0].value}千瓦</div>
        <div>${data[1].marker} ${data[1].seriesName}: ${data[1].value}元</div>
        `;
      return html;
    },
  },
  grid: {
    // left: "3%",
    // right: "4%",
    bottom: "45",
    // containLabel: true,
  },
  legend: {
    show: true,
    x: "center",
    y: "bottom",
    icon: "stack",
    itemWidth: 25,
    itemHeight: 10,
    textStyle: {
      color: "#97b3cb",
    },
    data: ["发电总量", "预估收益"],
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      axisLabel: {
        color: "#97b3cb",
      },
      // 修改刻度尺颜色
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      data: [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ],
    },
  ],
  yAxis: [
    {
      type: "value",
      name: "单位:元",
      min: 0,
      axisLabel: {
        formatter: "{value}",
        // y轴数据颜色
        textStyle: {
          color: "#97b3cb",
        },
      },
      // 单位 元 颜色
      axisLine: {
        show: false,
        lineStyle: {
          color: "#97b3cb",
        },
      },
      axisTick: {
        show: false,
      },
      // 背景虚线
      splitLine: {
        show: true,
        lineStyle: {
          color: "#11366e",
        },
      },
    },
    {
      type: "value",
      name: "发电总量：千瓦",
      min: 0,
      max: 1000,
      axisLabel: {
        formatter: "{value}",
        textStyle: {
          color: "#97b3cb", //y轴的字体颜色
        },
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: "#97b3cb", // 发电总量颜色
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          type: "dashed", // 线型为虚线
          color: "#97b3cb",
        },
      },
    },
  ],
  series: [
    {
      name: "发电总量",
      type: "line",
      stack: "总量",
      symbol: "circle",
      // symbolSize: 0,
      itemStyle: {
        normal: {
          color: "#0092f6",
          lineStyle: {
            color: "#0092f6",
            width: 1,
          },
          areaStyle: {},
        },
      },
      markPoint: {
        itemStyle: {
          normal: {
            color: "red",
          },
        },
      },
      data: [
        3220, 1520, 2070, 2034, 1100, 1030, 2100, 1001, 1504, 1900, 2003, 2500,
      ],
    },
    {
      name: "预估收益",
      type: "line",
      stack: "总量",
      symbol: "circle",
      // symbolSize: 0,
      itemStyle: {
        normal: {
          color: "#00d4c7",
          lineStyle: {
            color: "#00d4c7",
            width: 1,
          },
          areaStyle: {},
        },
      },
      data: [
        1030, 1050, 1070, 1234, 1110, 1130, 2010, 1001, 1154, 2190, 2003, 1500,
      ],
    },
  ],
};

//底部右侧数据
export const indexBottomRight = {
  title: {
    text: '27',
    subtext: '厂站总数',
    textStyle: {
      fontSize: 15,
      color: "#97b3cb",
      fontWeight: 600
    },
    subtextStyle: {
      fontSize: 11,
      color: "#97b3cb",
      fontWeight: 400
    },
    textAlign: "center", //图例文字居中显示
    x: "34%",   //距离左边的距离
    y: "36%"    //距离上边的距离
  },
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c}台 ({d}%)'
  },
  legend: {
    textStyle: { //图例文字的样式
      color: '#97b3cb',
      fontSize: 12
    },
    orient: 'vertical',
    bottom: "2%",
    right: "0%",
    data: ['未开始', '处理中', '已完成']
  },
  color: ["#f59a23", "#95f204", '#d9001b',],
  series: [
    {
      label: {

        width: 40,
        color: "#999",
        formatter: " {c}台 ",
        show: true
      },
      type: 'pie',
      radius: ["55%","70%"],
      center: ['36%', '50%'],
      selectedMode: 'single',
      data: [
        { value: 20, name: '未开始' },
        { value: 20, name: '处理中' },
        { value: 5, name: '已完成' },
      ],
    }
  ]

};
