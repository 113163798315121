<script>
import moment from "moment";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getRecord();
  },
  methods: {
    async getRecord() {
      const {
        data: { data },
      } = await this.$http.post("/RealMonitoring/record", {
        powerStationCode: localStorage.getItem("powerStationCode")
      });

      // data.forEach((item) => {
      //   item.endtime = moment(item.endtime).format("YYYY-MM-DD HH:mm:ss");
      // });
      this.list = data.slice(0,3);
    },
  },
};
</script>
<template>
  <div class="index-main">
    <div class="head">巡检实况</div>
    <div class="bottom">
      <!-- <el-table ref="table" :data="list" tooltip-effect="dark" style="width: 100%"
        height="calc(100vh - 240px)">
        <el-table-column label="巡查公司" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.powerStationName }}</template>
        </el-table-column>
        <el-table-column label="巡查人" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.principal }}</template>
        </el-table-column>
        <el-table-column label="巡查状态" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.performance }}</template>
        </el-table-column>
        <el-table-column label="巡查时间" width="120" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.endtime }}</template>
        </el-table-column>

      </el-table> -->
      <table>
        <thead>
          <tr>
            <td>巡查公司</td>
            <td>巡查人</td>
            <td>巡查状态</td>
            <td>巡查时间</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in list" :key="item.id">
            <td  class="ellipsis" style="max-width:100px" :title="item.powerStationName">{{ item.powerStationName }}</td>
            <td>{{ item.principal }}</td>
            <td>{{ item.performance }}</td>
            <td>{{ item.endtime }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style lang="less" scoped>
.index-main {
  height: 50%;
  width: 98%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-grow: 1;
  background-color: #132c45;
  border-radius: 1px;
  border: 1px solid #026795;
  margin-left: 10px;
  margin-top: 10px;
  .head {
    display: flex;
    border-bottom: 1px solid #075279;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
    color: #02a7f0;
    font-size: 16px;
    font-weight: 600;
  }
  .bottom {
    padding-bottom: 5px;
    table {
      width: 100%;
      border: none;
      text-align: center;
      color: #97b3cb;
      font-size: 14px !important;
    }
  }
}
//文本省略
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
