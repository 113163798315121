<template>
  <div class="index-main">
    <div class="head">告警统计（台）</div>
    <div class="two-card">
      <div class="title">
        <div class="title-item">
          <div class="label">数量</div>
          <div class="num" style="color: #00b2ff">25</div>
        </div>
        <div class="title-item">
          <div class="label online">在线</div>
          <div class="num" style="color: #00c97b">20</div>
        </div>
        <div class="title-item">
          <div class="label warn">告警</div>
          <div class="num" style="color: #f59a23">2</div>
        </div>
        <div class="title-item">
          <div class="label danger">断链</div>
          <div class="num" style="color: #d9001b">5</div>
        </div>
      </div>
    </div>
    <div>
      <div id="IndexBottomLeft" class="chart"></div>
    </div>
  </div>
</template>

<script>
import { indexBottomLeft} from "../../Menu/echartsOptions/indexBottom"
import * as echarts from "echarts";
export default {
   methods: {
    init() {
      setTimeout(() => {
        this.IndexBottomLeft = echarts.init(
          document.getElementById("IndexBottomLeft")
        );
        let option = indexBottomLeft;
        this.IndexBottomLeft.setOption(option);
      }, 200);
      window.onresize = function () {
        if (this.IndexBottomLeft) {
          this.IndexBottomLeft.resize(); // 调用 ECharts 实例的 resize 方法
        }
      }.bind(this);
    },
  },
  mounted() {
    // this.init();
  },
  beforeDestory() {
    this.IndexBottomLeft.dispose();
  },
};
</script>

<style lang="scss" scoped>
.index-main {
  // height: 3.375rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #132c45;
  border-radius: 1px;
  border: 1px solid #026795;
  // margin-top: 10px;
  .head {
    display: flex;
    border-bottom: 1px solid #075279;
    padding:10px;
    box-sizing: border-box;
    align-items: center;
    color: #02a7f0;
    font-size: 18px;
    font-weight: 600;
  }
   .two-card {
    padding: 0 10px;
  }
  .title {
    width: 98%;
    padding: 10px 10px 0 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .title-item {
      display: flex;
    }
    .label {
      color: #97b3cb;
      font-size: 16px;
      position: relative;
      &::after {
        content: ":";
        padding-left: 10px;
        color: #97b3cb;
      }
    }
    .num {
      padding-left: 10px;
      font-weight: 700;
      font-size: 20px;
      .unit {
        font-size: 16px;
        padding-left: 10px;
      }
    }
    // .online::before {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   width: 20px;
    //   left: -42%;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   aspect-ratio: 1;
    //   border-radius: 50%;
    //   background-color: #00c97b;
    // }
    // .warn::before {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   width: 20px;
    //   left: -42%;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   aspect-ratio: 1;
    //   border-radius: 50%;
    //   background-color: #f59a23;
    // }
    // .danger::before {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   width: 10px;
    //   left: -42%;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   aspect-ratio: 1;
    //   border-radius: 50%;
    //   background-color: #999;
    // }
  }
  .chart {
    width: 300px;
    height: 150px;
  }
}
</style>
