import { render, staticRenderFns } from "./IndexCenterMain.vue?vue&type=template&id=41148428&scoped=true"
import script from "./IndexCenterMain.vue?vue&type=script&lang=js"
export * from "./IndexCenterMain.vue?vue&type=script&lang=js"
import style0 from "./IndexCenterMain.vue?vue&type=style&index=0&id=41148428&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41148428",
  null
  
)

component.options.__file = "IndexCenterMain.vue"
export default component.exports