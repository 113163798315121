import { render, staticRenderFns } from "./IndexCenterLeft.vue?vue&type=template&id=25f67c46&scoped=true"
import script from "./IndexCenterLeft.vue?vue&type=script&lang=js"
export * from "./IndexCenterLeft.vue?vue&type=script&lang=js"
import style0 from "./IndexCenterLeft.vue?vue&type=style&index=0&id=25f67c46&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25f67c46",
  null
  
)

component.options.__file = "IndexCenterLeft.vue"
export default component.exports