<template>
  <div class="index-main">
    <div class="head">收益预估</div>
    <div id="indexBottomCenter" class="chart"></div>
  </div>
</template>

<script>
import { indexBottomCenter } from "../../Menu/echartsOptions/indexBottom";
import * as echarts from "echarts";

export default {
  data() {
    return {
      parmas: {
        cityCode: "340100",//340100
        powerStationCode: "1",//1
      },
    };
  },
  methods: {
    init() {

      setTimeout(() => {
        this.indexBottomCenter = echarts.init(
          document.getElementById("indexBottomCenter")
        );
         this.getRealMonitoring();
      }, 200);
      window.onresize = function () {
        if (this.indexBottomCenter) {
          this.indexBottomCenter.resize(); // 调用 ECharts 实例的 resize 方法
        }
      }.bind(this);
    },

    getRealMonitoring() {



       let option = indexBottomCenter;
      this.$http
        .post("/RealMonitoring/Earning", {
          ...this.parmas,
          // cityCode: localStorage.getItem("cityCode"),
          // powerStationCode: localStorage.getItem("powerStationCode")
        } )
        .then((res) => {
          if (res.data.code === 200) {
            option.series[0].data = res.data.data.map((item) =>  item.月总发电量);
            option.series[1].data = res.data.data.map((item) => item.预估收益);
             this.indexBottomCenter.setOption(option);
          }
        });
    },
  },

  mounted() {
    this.init();
  },
  beforeDestory() {
    this.indexBottomCenter.dispose();
  },
};
</script>

<style lang="scss" scoped>
.index-main {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #132c45;
  border-radius: 1px;
  border: 1px solid #026795;
  // margin-left: 10px;
  // margin-top: 10px;
  .head {
    display: flex;
    border-bottom: 1px solid #075279;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
    color: #02a7f0;
    font-size: 18px;
    font-weight: 600;
  }

  .chart {
    width: 100%;
    height: 100%;
    // margin-top: -0.4rem;
  }
}
</style>
