export const indexOptions = {
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c}台 ({d}%)'
  },
  legend: {
    textStyle: { //图例文字的样式
      color: '#97b3cb',
      fontSize: 12
    },
    orient: 'vertical',
    bottom: "10%",
    right: "0%",
    data: ['正常运行', '设备关闭', '故障停机', '通讯中断']
  },
  color: ["#00c97b", "#Ff6666", '#f59a23', "#999999"],
  series: [
    {
      label: {

        width: 40,
        color: "#999",
        formatter: " {c}台 ",
        show: true
      },
      type: 'pie',
      radius: '50%',
      center: ['36%', '50%'],
      selectedMode: 'single',
      data: [
        { value: 50, name: '正常运行' },
        { value: 25, name: '设备关闭' },
        { value: 25, name: '故障停机' },
        { value: 0, name: '通讯中断' }
      ],
    }
  ]

}
