<script>
import { topChartOption } from "./echartsOptions/topChart";
import * as echarts from "echarts";
import IndexCenterLeft from "../components/Index/IndexCenterLeft.vue";
import IndexCenterMain from "../components/Index/IndexCenterMain.vue";
import IndexCenterRightTop from "../components/Index/IndexCenterRightTop.vue";
import IndexCenterRightBottom from "../components/Index/indexCenterRightBottom.vue";
import IndexBottomLeft from "../components/Index/IndexBottomLeft.vue";
import IndexBottomCenter from "../components/Index/IndexBottomCenter.vue";
import IndexBottomRight from "../components/Index/IndexBottomRight.vue";

export default {
  components: {
    IndexCenterLeft,
    IndexCenterMain,
    IndexCenterRightTop,
    IndexCenterRightBottom,
    IndexBottomLeft,
    IndexBottomCenter,
    IndexBottomRight,
  },
  mounted() {
    // this.init();
  },
  data() {
    return {
      electricity: {}, //统计
      psTitle: require('@/assets/images/psTitle.png'),
      ranking: [], //排名
      realTime: [], // 实时经警告
      powerStationCode: localStorage.getItem("powerStationCode"),
      electricityDay: [], //当日发电量
      powerStationCount: [], //场站状态
      topList: [
        { title: "正常运行", num: 0, color: "#63a103" },
        { title: "设备关闭", num: 0, color: "#02a7f0" },
        { title: "故障停机", num: 0, color: "#655f7d" },
        { title: "通讯中断", num: 0, color: "#8c9da8" },
      ],
    };
  },
  created() {
    this.getGElectricity();
    this.getCount();
    this.getSummary();
    //dd
    // this.getGeneTop()
    // this.getReal()
  },
  computed: {
    totalNum() {
      // 计算总数
      return this.topList.reduce((total, item) => total + item.num, 0);
    },
    title(){
      return localStorage.getItem("powerStationName")
    },
  },
  methods: {
    customColorMethod(percentage) {
      if (percentage < 30) {
        return "#D9001B";
      }
      if (percentage < 70) {
        return "#e6a23c";
      }
      return "#67c23a";
    },
    // 发电统计currEfficiency
    async getGElectricity() {
      const res = await this.$http.post(
        "/powerStationDevice/queryByPowerStation",
        { powerStationCode: this.powerStationCode }
      );
      if (res.data.code == 200) {
        this.electricity = res.data.data;

      } else {
        this.$message.error(res.data);
      }
    },
    // 获取发电排名
    async getGeneTop() {
      const {
        data: { data },
      } = await this.$http.post("/RealMonitoring/PCgeneTop", {
        powerStationCode: localStorage.getItem("powerStationCode")
      });
      this.ranking = data;
    },
    // 获取厂站状态
    async getCount() {
      const res = await this.$http.post("/powerStationDevice/queryList", {
        powerStationCode: this.powerStationCode,
      });
      console.log(res, 73);
      if (res.data.code == 200) {
        this.dataList = res.data.data;
        // 运行
        this.topList[0].num = this.dataList.filter(
          (item) => item.runingStatus == 1
        ).length;
        // 关闭
        this.topList[1].num = this.dataList.filter(
          (item) => item.runingStatus == 0
        ).length;
        //故障
        this.topList[2].num = this.dataList.filter(
          (item) => item.runingStatus == 2
        ).length;
        // 通讯中断
        this.topList[3].num = this.dataList.filter(
          (item) => item.connectStatus == 0
        ).length;
      }
    },

    // 获取近三月发电量
    async getSummary() {
      let currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      let month2 = ("0" + (currentDate.getMonth() + 1 - 3)).slice(-2);

      let summaryEddDate = `${year}-${month}`;
      let summaryBeDate = `${year}-${month2}`;
      console.log(summaryBeDate, 111);
      const res = await this.$http.post("/powerStationDeviceSummary/summary", {
        dateFormat: "%Y-%m",
        powerStationCode: this.powerStationCode,
        summaryBeDate,
        summaryEddDate,
      });
      console.log(res, 118);
      if (res.data.code === 200) {
        this.electricityDay = res.data.data;
        console.log(this.electricityDay, 121);
      } else {
        this.$message.error(res.data.message);
      }
    },
    //获取实时告警
    async getReal() {
      const {
        data: { data },
      } = await this.$http.post("/RealMonitoring/PCReal", {
        powerStationCode: localStorage.getItem("powerStationCode")
      });
      this.realTime = data.filter((item) =>
        ["1", "2", "3", "4"].includes(item.alarmlevel)
      );
    },
  },
  beforeDestory() {
    this.topChart.dispose();
  },
  // created() {
  //   Promise.all([this.getGElectricity(), this.getGeneTop(), this.getReal()]);
  // },
};
</script>
<template>
  <div class="monitor-index">
    <el-card>
      <div class="flex-end">
        <div class="psName-box">
          <el-image
              :src="psTitle"
              style="width: 20px; height: 20px; margin-right: 10px"
            ></el-image>
          <span class="psName">{{title}}</span>
        </div>
      </div>
      <div class="head-box">
        <!-- 集团公司发电 -->
        <!-- <li class="item">
          <div class="item-head">
            <div class="left-head">
              <i class="el-icon-office-building"></i>
              <span class="head-title">集团公司发电总量统计</span>
            </div>
            <div class="right-head">
              <i class="el-icon-office-building"></i>
            </div>
          </div>
          <div class="item-bottom">
            <div class="line1 line">
              <div class="line-left">子公司</div>
              <div class="line-right">
                <span class="num">8000</span><span class="unit">MV</span>
              </div>
            </div>
            <div class="line2 line">
              <div class="line-left">分公司</div>
              <div class="line-right">
                <span class="num">7000</span><span class="unit">MV</span>
              </div>
            </div>
            <div class="line3 line">
              <div class="line-left">项目公司</div>
              <div class="line-right">
                <span class="num">9000</span><span class="unit">MV</span>
              </div>
            </div>
          </div>
        </li> -->
        <!-- 集团公司数量 -->
        <!-- <li class="item">
          <div class="item-head">
            <div class="left-head">
              <i class="el-icon-office-building"></i>
              <span class="head-title">集团公司数量统计</span>
            </div>
            <div class="right-head">
              <i class="el-icon-office-building"></i>
            </div>
          </div>
          <div class="item-bottom">
            <div class="line1 line">
              <div class="line-left">子公司</div>
              <div class="line-right line-blue">
                <span class="num">300</span><span class="unit">个</span>
              </div>
            </div>
            <div class="line2 line">
              <div class="line-left">分公司</div>
              <div class="line-right line-blue">
                <span class="num">270</span><span class="unit">个</span>
              </div>
            </div>
            <div class="line3 line">
              <div class="line-left">项目公司</div>
              <div class="line-right line-blue">
                <span class="num">900</span><span class="unit">个</span>
              </div>
            </div>
          </div>
        </li> -->
        <!-- 发电统计 -->
        <li class="item">
          <div class="item-head">
            <div class="left-head">
              <i class="el-icon-office-building"></i>
              <span class="head-title">发电统计</span>
            </div>
            <div class="right-head">
              <i class="el-icon-office-building"></i>
            </div>
          </div>
          <div class="item-third">
            <div class="center-circle">
              <div class="circle-text">
                <span v-if="electricity.currEfficiency" class="num">{{
                  electricity.currEfficiency > 1000
                    ? (electricity.currEfficiency / 1000).toFixed(0)
                    : (electricity.currEfficiency).toFixed(0)
                }}</span>
                <span class="num" v-else>0w</span>
                <span v-if="electricity.currEfficiency" class="unit" style="font-size: 10px">{{
                  electricity.currEfficiency > 1000 ? "mw" : "kw"
                }}</span>

                <span class="text-class" style="font-size: 10px">发电功率</span>
              </div>
            </div>
            <div class="bottom-text">
              <div class="daily"></div>
              <span class="label">电量</span>
              <span class="num">{{ electricity.currDayPowerNum ? Number(electricity.currDayPowerNum).toFixed(0) : 0 }}</span>
              <span class="unit">度</span>
            </div>
          </div>
        </li>
        <!-- 厂站状态 -->
        <li class="item">
          <div class="item-head">
            <div class="left-head">
              <i class="el-icon-office-building"></i>
              <span class="head-title">厂站状态</span>
            </div>
            <!-- <div class="right-head">
              1
            </div> -->
          </div>
          <div style="padding: 10px">
            <div class="list">
              <div v-for="(item, index) in topList" :key="index">
                <div class="item-title">
                  <span>{{ item.title }}</span> <span>{{ item.num }}台</span>
                </div>
                <div class="progress-bar">
                  <div
                    class="progress"
                    :style="{ width: (item.num / totalNum) * 100 + '%' }"
                  ></div>
                </div>
              </div>
            </div>
            <!-- <div class="list">
              <div v-for="(item, index) in topList" :key="index" class="item">
                <div class="item-title">
                  <span>{{ item.title }}</span>
                  <span>{{ item.num }}台</span>
                </div>
                <div>
                  <el-progress
                    :show-text="false"
                    :percentage="item.num"
                    :color="item.color"
                  />
                </div>
              </div>
            </div> -->

            <!-- <div ref="topChart" id="topChart" class="fourth-left"></div> -->
          </div>
        </li>
        <!-- 实时告警 -->
        <li class="item">
          <div class="item-head">
            <div class="left-head">
              <i class="el-icon-office-building"></i>
              <span class="head-title">实时告警</span>
            </div>
            <div class="right-head">
              <i class="el-icon-office-building"></i>
            </div>
          </div>
          <div class="item-fourth flex-grow">
            <ul class="fourth-items">
              <li class="item" v-for="(item, index) in realTime" :key="index">
                <div class="label" v-if="item.alarmlevel == 1">
                  提示告警（{{ item.alarmCount }}条）
                </div>
                <div class="label" v-if="item.alarmlevel == 2">
                  次要告警（{{ item.alarmCount }}条）
                </div>
                <div class="label" v-if="item.alarmlevel == 3">
                  重要告警（{{ item.alarmCount }}条）
                </div>
                <div class="label" v-if="item.alarmlevel == 4">
                  紧急告警（{{ item.alarmCount }}条）
                </div>
                <div class="progress">
                  <el-progress
                    :show-text="false"
                    :percentage="item.alarmCount * 1"
                    :color="customColorMethod"
                  />
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- 发电排名（等效小时） -->
        <li class="item">
          <div class="item-head">
            <div class="left-head">
              <el-icon><OfficeBuilding /></el-icon>
              <span class="head-title">发电排名（等效小时）</span>
            </div>
            <div class="right-head">
              <el-icon><ArrowRight /></el-icon>
            </div>
          </div>
          <div class="item-fourth flex-grow">
            <ul class="fourth-items">
              <li class="item" v-for="(item, index) in ranking" :key="index">
                <div class="label">
                  {{ item.powerStationName }}
                </div>
                <div class="progress">
                  <el-progress
                    :show-text="false"
                    :percentage="item.equivalentHours * 1"
                    :color="customColorMethod"
                  />
                </div>
              </li>
            </ul>
          </div>
        </li>
      </div>
      <div class="center-box">
        <div class="center-box-left">
          <IndexCenterLeft :topList="topList" :electricity="electricity" />
        </div>
        <div class="center-box-center">
          <IndexCenterMain />
        </div>
        <div class="center-box-right">
          <IndexCenterRightTop :electricityDay="electricityDay" />
          <IndexCenterRightBottom />
        </div>
      </div>

      <div class="footer-box">
        <div class="footer-box-left">
          <IndexBottomLeft />
        </div>
        <div class="footer-box-center">
          <IndexBottomCenter />
        </div>
        <div class="footer-box-right">
          <IndexBottomRight />
        </div>
      </div>
    </el-card>
  </div>
</template>

<style lang="less" scoped>
.monitor-index {
  background-color: #223f6c;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  .el-card {
    height: 88vh;
    // overflow: auto;
    overflow-y: auto;
    border: none;
    box-sizing: border-box;
    background-color: #223f6c;
    &::-webkit-scrollbar {
      width: 0;
    }
    /deep/.el-card__body {
      padding: 0;
      box-sizing: border-box;
    }
    .psName-box{
      // width: calc((100% - 40px) / 4);
      display: flex;
      align-items: center;
      height: 25px;
      line-height: 25px;
      padding-bottom: 5px;
      padding-right: 20px;
      .psName{
        color: #f0fcf8;
        font-weight: bold;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .head-box {
      display: flex;
      justify-content: space-between;
      position: relative;
      > li {
        display: flex;
        flex-direction: column;
        background-color: rgba(19, 44, 69, 1);
        width: calc((100% - 40px) / 4);
        border: 1px solid #026795;
        border-radius: 1px;
        i {
          color: #02a7f0;
        }
        .item-bottom {
          box-sizing: border-box;
          padding: 10px;
          .line {
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            position: relative;
            justify-content: space-between;
            &::before {
              content: "";
              display: block;
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 0;
              border: 1px dashed #263f58;
            }
            .line-left {
              color: #97b3cb;
              margin-left: 10px;
              font-weight: 600;
              font-size: 18px;
              &::after {
                content: ":";
                padding-left: 10px;
                font-size: 18px;
                font-weight: 500;
              }
            }
            .line-right {
              margin-right: 10px;
              color: #95f204;
              .num {
                font-weight: 300;
                font-size: 20px;
              }
              .unit {
                font-size: 14px;
                font-weight: 400;
                padding-left: 10px;
              }
            }
            .line-blue {
              color: #02a7f0;
            }
          }
        }
        .item-head {
          display: flex;
          box-sizing: border-box;
          border-bottom: 1px solid #075279;
          padding: 10px;
          justify-content: space-between;
          align-items: center;
          .left-head {
            display: flex;
            align-items: center;
            .head-title {
              color: #02a7f0;
              font-size: 18px;
              font-weight: 600;
              padding-left: 10px;
            }
          }
        }
        .item-third,
        .flex-grow {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          box-sizing: border-box;
          justify-content: center;
          align-items: center;
          .center-circle {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
            width: 100px;
            border-radius: 50%;
            aspect-ratio: 1/1;
            border: 1px solid #7ed0ff;
            border-bottom-color: transparent;
            position: relative;
            .circle-text {
              display: flex;
              height: 45%;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              padding-top: 10px;
              .num {
                color: #7ed0ff;
                font-size: 18px;
                transform: translateY(0.14rem);
              }
              .unit {
                color: #97b3cb;
                font-size: 18px;
              }
              .text-class {
                font-size: 12px;
                position: absolute;
                bottom: 4px;
                color: #97b3cb;
              }
            }
            &::before {
              position: absolute;
              content: "";
              display: block;
              top: 50%;
              left: 50%;
              aspect-ratio: 1/1;
              border-radius: 50%;
              height: 120px;
              width: 120px;
              border: 1px solid #95f204;
              border-bottom-color: transparent;
              transform: translate(-50%, -50%);
            }
            &::after {
              position: absolute;
              content: "";
              display: block;
              top: 50%;
              left: 50%;
              aspect-ratio: 1/1;
              border-radius: 50%;
              height: 70px;
              width: 70px;
              border: 1px solid #95f204;
              border-bottom-color: transparent;
              transform: translate(-50%, -50%);
            }
            // &::after {
            //   position: absolute;
            //   content: "发电功率";
            //   bottom: 0;
            //   left: 50%;
            //   font-size: 0.12rem;
            //   transform: translateX(-50%);
            //   color: #97b3cb;
            // }
          }
          .bottom-text {
            display: flex;
            align-items: center;
            font-size: 16px;
            .label {
              color: #97b3cb;
              font-size: 16px;
              margin-left: 10px;
              padding-right: 10px;
            }
            .num {
              color: #00b2e6;
            }
            .unit {
              color: #97b3cb;
            }
            .daily {
              border-radius: 50%;
              border: 1px solid #0a6d9f;
              position: relative;
              &::before {
                content: "日";
                position: absolute;
                top: 50%;
                left: 50%;
                font-size: 18px;
                color: #02a7f0;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
        .item-fourth {
          display: flex;
          align-items: center;
          .fourth-left {
            height: 100%;
            width: 100%;
          }
          ul.fourth-items {
            box-sizing: border-box;
            padding: 10px;
            margin: 0;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            li.item {
              border: none;
              height: 230px;
              .label {
                color: #97b3cb;
                padding-bottom: 2px;
                font-size: 18px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }
              /deep/.progress {
                color: red;
                .el-progress-bar__outer {
                  background-color: #132c45 !important;
                  border: 1px solid #02a7f0 !important;
                }
              }
            }
          }
        }
      }
    }
    .center-box {
      margin-top: 10px;
      display: flex;
      .center-box-left,
      .center-box-right {
        width: 25%;
        // height: 100%;
        // background-color: red;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
      }
      .center-box-left {
        padding-right: 10px;
      }
      .center-box-center {
        flex-grow: 1;
        height: 100%;
      }
    }

    .footer-box {
      margin-top: 10px;
      display: flex;
      height: calc(300px - 20px);
      .index-main{
        height: 100%;
      }
      .footer-box-left {
        width: 25%;
        height: 100%;
        box-sizing: border-box;
        padding-right: 10px;
      }

      .footer-box-right {
        width: 25%;
        height: 100%;
        box-sizing: border-box;
        // background-color: green;
      }

      .footer-box-center {
        flex-grow: 1;
        height: 100%;
        // background-color: pink;
      }
    }
  }

  .list {
    // height: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // height: 80px;
  }
  .item {
    // margin-top: 10px;
    width: 47%;
    // height: 30px;
    .item-title {
      display: flex;
      justify-content: space-between;
      color: #fff;
      margin-bottom: 10px;
    }
  }
}

.progress-bar {
  width: 170px; /* 进度条整体宽度 */
  background-color: #ddd; /* 进度条背景颜色 */
  border-radius: 10px; /* 可选：圆角边框 */
}

.progress {
  // width: 50%; /* 进度条的完成部分 */
  height: 10px; /* 进度条的高度 */
  background-color: #4caf50; /* 进度条的前景色 */
  border-radius: 10px; /* 可选：圆角边框 */
}
</style>
