import { render, staticRenderFns } from "./IndexBottomCenter.vue?vue&type=template&id=3a0dded3&scoped=true"
import script from "./IndexBottomCenter.vue?vue&type=script&lang=js"
export * from "./IndexBottomCenter.vue?vue&type=script&lang=js"
import style0 from "./IndexBottomCenter.vue?vue&type=style&index=0&id=3a0dded3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a0dded3",
  null
  
)

component.options.__file = "IndexBottomCenter.vue"
export default component.exports