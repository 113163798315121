<template>
  <div class="index-main">
    <div class="head">工单统计</div>
    <div class="two-card">
      <div class="title">
        <div class="title-item">
          <div class="label">全部</div>
          <div class="num" style="color: #00b2ff">{{ count }}</div>
        </div>
        <div class="title-item">
          <div class="label online">未开始</div>
          <div class="num" style="color: #f59a23">
            {{ count1 }}
          </div>
        </div>
        <div class="title-item">
          <div class="label warn">处理中</div>
          <div class="num" style="color: #00c97b">   {{ count2 }}</div>
        </div>
        <div class="title-item">
          <div class="label danger">已完成</div>
          <div class="num" style="color: #d9001b">   {{ count3 }}</div>
        </div>
      </div>
    </div>
    <div class="canvas-box">
      <div id="indexBottomRight" class="chart"></div>
    </div>
  </div>
</template>

<script>
import { indexBottomRight } from "../../Menu/echartsOptions/indexBottom";
import * as echarts from "echarts";
export default {
  data() {
    return {
      powerStationCode: "20240322173012121238012653664",
      count: "",
      count1: "", // 未开始
      count2: "", // 处理中
      count3: "", // 已完成
    };
  },
  methods: {
    init() {
      setTimeout(() => {
        this.indexBottomRight = echarts.init(
          document.getElementById("indexBottomRight")
        );
        this.getWorkStatistics();
      }, 200);
      window.onresize = function () {
        if (this.indexBottomRight) {
          this.indexBottomRight.resize(); // 调用 ECharts 实例的 resize 方法
        }
      }.bind(this);
    },
    // 获取工单统计
    async getWorkStatistics() {
      const {
        data: { data },
      } = await this.$http.post("/RealMonitoring/PCWorkStatistics", {
        deptCode: this.deptCode,
        powerStationCode: localStorage.getItem("powerStationCode")
      });

      let option = indexBottomRight;
      option.series[0].data = data.map((item) => {
        let status = "";
        if (item.workOrderOverStatus == 0) {
          this.count1 = item.count;
          status = "未开始";
        } else if (item.workOrderOverStatus == 1) {
          this.count2 = item.count;
          status = "处理中";
        } else if (item.workOrderOverStatus == 2) {
          this.count3 = item.count;
          status = "已完成";
        }
        return { value: Number(item.count), name: status };
      });
      this.count = option.title.text = data.reduce((total, item) => {
        return total + Number(item.count);
      }, 0);
      this.indexBottomRight.setOption(option);
    },
  },
  mounted() {
    this.init();
  },
  beforeDestory() {
    this.indexBottomRight.dispose();
  },
};
</script>

<style lang="scss" scoped>
.index-main {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #132c45;
  border-radius: 1px;
  border: 1px solid #026795;
  margin-left: 10px;
  // margin-top: 10px;
  .head {
    display: flex;
    border-bottom: 1px solid #075279;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
    color: #02a7f0;
    font-size: 20px;
    font-weight: 600;
  }
  .two-card {
    padding: 0 10px;
  }
  .title {
    width: 98%;
    padding: 10px 10px 0 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .title-item {
      display: flex;
    }
    .label {
      color: #97b3cb;
      font-size: 14px;
      position: relative;
      &::after {
        content: ":";
        padding-left: 10px;
        color: #97b3cb;
      }
    }
    .num {
      padding-left: 10px;
      font-weight: 700;
      font-size: 20px;
      .unit {
        font-size: 16px;
        padding-left: 10px;
      }
    }
  }

  .chart {
    width: 100%;
    height: 150px;
  }
}

.canvas-box {
  position: relative;

  .count {
    position: absolute;
    text-align: center;
    left: 27%;
    top: 39%;
    color: #97b3cb;
    font-size: 20px;
  }
}
</style>
