<script>
import * as echarts from "echarts";
import { indexOptions } from "../../Menu/echartsOptions/indexCenterLeftChart";
export default {
  props: {
    topList: {
      type: Array,
      default: () => [],
    },
    electricity: {
      type: Object,
      dafault: () => {},
    },
  },
  data() {
    return {
      chartIndex: null,
    };
  },
  methods: {
    init() {
      setTimeout(() => {
        this.chartIndex = echarts.init(document.getElementById("chartIndex"));
        indexOptions.series[0].data = this.topList.map((item) => {
          return { name: item.title, value: item.num };
        });

        let option = indexOptions;
        this.chartIndex.setOption(option);
      }, 200);
    },
  },
  mounted() {
    this.init();
  },
  beforeDestory() {
    this.chartIndex.dispose();
  },
};
</script>
<template>
  <div class="index-main">
    <div class="head">逆变器统计（台）</div>
    <div class="bottom">
      <div class="two-card">
        <!-- <div class="title" style="width: 97%">
          <div class="title-item">
            <div class="label">正常运行</div>
            <div class="num" style="color: #00b2ff">{{topList[0].num}}</div>
          </div>
          <div class="title-item">
            <div class="label online">关闭</div>
            <div class="num" style="color: #00c97b">{{topList[0].num}}</div>
          </div>
          <div class="title-item">
            <div class="label warn">故障停机</div>
            <div class="num" style="color: #f59a23">2</div>
          </div>
          <div class="title-item">
            <div class="label danger">通讯中断</div>
            <div class="num" style="color: #d9001b">5</div>
          </div>
        </div> -->
        <div class="title" style="width: 97%">
          <div class="title-item">
            <div class="label">正常运行</div>
            <div class="num" style="color: #00b2ff">
              <span>{{ topList[0].num }}</span>
            </div>
          </div>
          <div class="title-item">
            <div class="label">设备关闭</div>
            <div class="num" style="color: #00b2ff">
              <span>{{ topList[1].num }}</span>
            </div>
          </div>
        </div>

        <div class="title" style="width: 97%">
          <div class="title-item2">
            <span class="label" style="width: 120px">故障停机</span>
            <span style="color: #00b2ff">
              {{ topList[2].num }}
            </span>
          </div>
          <div class="title-item2">
            <span class="label" style="width: 120px">通讯中断</span>
            <span style="color: #00b2ff">
              {{ topList[3].num }}
            </span>
          </div>
        </div>
        <div class="title" style="width: 97%">
          <div class="title-item2">
            <span class="label" style="width: 120px">输出电量</span>
            <span style="color: #00b2ff">
              <span v-if="electricity.currDayPowerNum">{{
                Number(electricity.currDayPowerNum).toFixed(0)
              }}</span>
              <span v-else>{{ 0 }}</span>
              <span class="unit">度</span>
            </span>
          </div>
          <div class="title-item2">
            <span class="label" style="width: 120px">发电功率</span>
            <span style="color: #00b2ff; width: 100px">
              <span v-if="electricity.currEfficiency">
                {{
                  electricity.currEfficiency > 1000
                    ? (electricity.currEfficiency / 1000).toFixed(0)
                    : (electricity.currEfficiency).toFixed(0)
                }}
              </span>
              <span v-else>{{ 0 }}</span>

              <span class="unit">
                {{ electricity.currEfficiency > 1000 ? "mw" : "kw" }}
              </span>
            </span>
          </div>
        </div>
        <!-- <div class="title" style="width: 97%">
          <div class="title-item">
            <div class="label">辐射强度</div>
            <div class="num" style="color: #00b2ff">
              <span>25</span><span class="unit">MV</span>
            </div>
          </div>
          <div class="title-item">
            <div class="label">并网功率</div>
            <div class="num" style="color: #00b2ff">
              <span>25</span><span class="unit">KW</span>
            </div>
          </div>
        </div> -->
      </div>
      <div id="chartIndex" class="chart"></div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.index-main {
  display: flex;
  // height: calc(100% - 40px);
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #132c45;
  border-radius: 1px;
  border: 1px solid #026795;
  .head {
    display: flex;
    border-bottom: 1px solid #075279;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
    color: #02a7f0;
    font-size: 18px;
    font-weight: 600;
  }
  .bottom {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    box-sizing: border-box;
    padding-left: 10px;
    .two-card {
      height: fit-content;
    }
    .title {
      width: 98%;
      padding: 10px 20px 0 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .title-item {
        display: flex;
      }
      .label {
        color: #97b3cb;
        font-size: 18px;
        position: relative;
        &::after {
          content: ":";
          padding-left: 10px;
          color: #97b3cb;
        }
      }
      .num {
        padding-left: 10px;
        // font-weight: 700;
       //   font-size: 20px;
        .unit {
          font-size: 16px;
          padding-left: 10px;
        }
      }
      // .online::before {
      //   content: "";
      //   display: block;
      //   position: absolute;
      //   width: 20px;
      //   left: -42%;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   aspect-ratio: 1;
      //   border-radius: 50%;
      //   background-color: #00c97b;
      // }
      // .warn::before {
      //   content: "";
      //   display: block;
      //   position: absolute;
      //   width: 20px;
      //   left: -42%;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   aspect-ratio: 1;
      //   border-radius: 50%;
      //   background-color: #f59a23;
      // }
      // .danger::before {
      //   content: "";
      //   display: block;
      //   position: absolute;
      //   width: 20px;
      //   left: -42%;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   aspect-ratio: 1;
      //   border-radius: 50%;
      //   background-color: #999;
      // }
    }
    .chart {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 300px;
    }
  }
}
</style>
